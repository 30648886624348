@mixin main_font() {
  // font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-family: Seravek, "Gill Sans Nova", Ubuntu, Calibri, "DejaVu Sans",
    source-sans-pro, sans-serif;
}

@font-face {
  font-family: "FluxischElse";
  src: url("./assets/FluxischElse-Regular.woff2") format("woff2");
}

$chainprinter_path: "./assets/Chainprinter\ W00\ Regular/Chainprinter\ W00\ Regular";
@font-face {
  font-family: "Chainprinter";
  src: url($chainprinter_path + ".woff2") format("woff2"),
    url($chainprinter_path + ".woff") format("woff");
}

$backgroundColor: #f8f2eb;
$primary: #27372e;
$secondary: #391854;

$hoverOpacity: 0.6;
$yellow: rgba(#f8ee53, $hoverOpacity);
$goldenrod: rgba(#e7bb20, $hoverOpacity);
$orange: rgba(#e97724, $hoverOpacity);

html,
body {
  background-color: $backgroundColor;
}

h1 {
  font-family: "FluxischElse";
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Chainprinter";
}

p {
  @include main_font();
  font-size: 1.25rem;

  &.bigger {
    font-size: 1.4rem;
  }
}
a {
  color: $primary;
}

nav {
  background-color: rgba($backgroundColor, 0.7);
  backdrop-filter: blur(10px);
}
.navbar-brand {
  white-space: wrap;
}
main {
  padding-top: 120px;
}
.btn {
  background-color: rgba($primary, 0.9);
  border-color: rgba($primary, 0.9);
  transition: background-color 250ms, border-color 250ms;

  &:hover {
    background-color: $primary;
    border-color: $primary;
  }
  &:active,
  &:focus-visible {
    background-color: rgba($primary, 0.8) !important;
    border-color: rgba($primary, 0.8) !important;
  }
}

.logo-container {
  .logo {
    max-height: 50vh;
  }
  h2 {
    color: $secondary;
  }
}

.event-item {
  border-radius: 0.75rem;

  &.has-modal {
    border: 1px solid rgba($primary, 0.15);
    margin-bottom: 1rem;

    .plus-sign {
      color: $primary;
      border: 1px solid $primary;
      background-color: $backgroundColor;
      font-size: 2rem;
      text-align: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 1.75rem;
      transition: color 250ms, background-color 250ms;
    }
  }
  &.has-modal:hover {
    cursor: pointer;

    .plus-sign {
      color: #fff;
      background-color: $primary;
    }
  }

  .border-start {
    border-left-color: rgba($primary, 0.25) !important;
  }
}
.day-1 .event-item.has-modal:hover {
  background-image: linear-gradient($yellow 0 0);
}
.day-2 .event-item.has-modal:hover {
  background-image: linear-gradient($goldenrod 0 0);
}
.day-3 .event-item.has-modal:hover {
  background-image: linear-gradient($orange 0 0);
}

.links {
  font-family: "Chainprinter";
  font-size: 0.9em;
}

.tickets-table {
  overflow: hidden;
  border-radius: 0.75rem;
}
